import { useCallback, useEffect, useState } from 'react';
import { COLORS } from '../../constants/colors';
import { languageList } from '../../constants/languageList';
import AutoCompleteMultiSelect from './AutoCompleteMultiSelect';
import { useLocale } from '../../contexts/LocalizationContext';

const SelectSub = ({
  handleSelectedCountryList,
  handleRemoveLang,
  removeCrossButton,
  index,
}: {
  handleSelectedCountryList: any;
  handleRemoveLang: any;
  removeCrossButton: any;
  index: number;
}) => {

  const { messages } = useLocale();
  const {
    select_language_msg
  } = messages || {};
  const [countryArr, setCountryArr] = useState<
    { value: string; name: string }[]
  >([]);

  const [selectedCountryListSingle, setSelectedCountryListSingle] = useState<{
    value: string;
    name: string;
  }>();
  const handleChange = useCallback((value: any) => {
    setSelectedCountryListSingle(value);
  }, []);

  useEffect(() => {
    if (selectedCountryListSingle) {
      handleSelectedCountryList(selectedCountryListSingle, index);
    }
  }, [selectedCountryListSingle, handleSelectedCountryList]);

  useEffect(() => {
    if (languageList) {
      const countryArr = languageList?.map((cur: any) => ({
        name: cur.name,
        value: String(cur.locale),
      }));
      setCountryArr(countryArr);
    }
  }, [languageList]);

  return (
    <AutoCompleteMultiSelect
      style={{ background: '#2C2C2C', color: COLORS.white }}
      error={undefined}
      options={countryArr}
      index={index}
      removeCrossButton={removeCrossButton}
      onClear={handleRemoveLang}
      handleChange={handleChange}
      value={selectedCountryListSingle}
      placeholder={select_language_msg}
    />
  );
};

export default SelectSub;
