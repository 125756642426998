import styled from 'styled-components';
import { COLORS } from '../../constants/colors';
import { WIDTH } from '../../constants/screenResolution';

export const Section1Wrapper = styled.div`
  background: ${COLORS.black};
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  padding: 56px;
  @media (max-width: ${WIDTH.laptopMid}) {
    padding: 56px 16px;
    height: fit-content;
    height: 70vh;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 50px 8px;
    height: 100vh;
  }
`;

export const DAFContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 112px;
  align-items: center;
  justify-content: center;
  gap: 48px;
  @media (max-width: ${WIDTH.mobileMax}) {
    margin-top: 60px;
  }
`;

export const DAFBody: any = styled.div`
  min-width: 552px;
  padding: 56px;
  border-radius: 12px;
  background: ${'#171717'};
  @media (max-width: ${WIDTH.mobileMax}) {
    padding: 24px;
    min-width: 0%;
    margin: 0px 16px;
  }
`;
export const DAFBodyHeading = styled.div`
  font: 20px/30px Onest-SemiBold;
  color: ${COLORS.white};
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 18px/28px Onest-SemiBold;
  }
`;

export const DAFBodySubHeading = styled.div`
  font: 16px/21px Onest-Regular;
  margin-bottom: 12px;
  margin-top: 32px;
  color: ${(props) => props.theme.palette.text.t3};
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 14px/18px Onest-Regular;
    margin-top: 16px;
  }
`;

export const IAgreeToTnCTextAff = styled.div`
  align-items: center;
  font: 0.875rem/1.125rem Onest-Medium;
  color: ${(props) => props.theme.palette.text.t2};
  a {
    padding: 0 0 0 4px;
    color: ${(props) => props.theme.palette.primary.main};
  }
`;

export const ButtonContentWrapperAffiliate = styled.div`
  display: flex;
  min-width: 214px;
  gap: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  @media (max-width: ${WIDTH.tabletMax}) {
    justify-content: center;
    min-width: 100%;
  }
`;

export const DAFBodyAffiliateTypes = styled.div`
  font: 16px/21px Onest-Regular;
  color: ${COLORS.white};
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 16px/20px Onest-Regular;
    margin-top: 16px;
  }
`;
export const ListTypeCheckboxes = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
`;

export const SubHeadingDesc = styled.div`
  margin-top: 8px;
  color: ${(props) => props.theme.palette.text.t3};
  font: 12px/16.2px Onest-Regular;
`;
export const DAFHeading = styled.div`
  font: 40px/54px Onest-Bold;
  margin: 0;
  color: ${COLORS.white}!important;
  @media (max-width: ${WIDTH.mobileMax}) {
    font: 24px/20px Onest-Bold;
    margin-top: 0px;
  }
`;

export const Section1Container = styled.div`
  //   max-width: 1232px;
  width: 75%;
  padding: 0 16px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 80px;
  @media (max-width: ${WIDTH.tabletMax}) {
    flex-direction: column;
    gap: 48px;
    padding: 0;
    margin: 0;
    width: 100%;
  }
`;

export const Section1Container2 = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Section1Content = styled.div`
  display: flex;
  width: 70%;
  flex-direction: column;
  gap: 32px;
  z-index: 1;
  h6 {
    margin: 0;
    font: 3rem/3.9rem Onest-Bold;
    color: ${COLORS.white};
  }
  div {
    font: 20px/27px Onest-Regular;
  }
  span {
    margin: 0;
    font: 3rem/3.9rem Onest-Bold;
    color: ${(props) => props.theme.palette.primary.main};
  }
  @media (max-width: ${WIDTH.laptopMid}) {
    h6 {
      font: 2.3rem /2.8rem Onest-Bold;
      margin-bottom: 8px;
    }
    gap: 32px;
  }
  @media (max-width: ${WIDTH.tabletMax}) {
    text-align: center;
    width: 100%;
    gap: 32px;
    h6 {
      // margin-top: -124px;
      font: 1.5rem /2rem Onest-Bold;
    }
    div {
      justify-content: center;
      font: 18px/20px Onest-Regular;
      padding: 0px 16px;
    }
    span {
      font: 2.5rem/2.9rem Onest-Bold;
    }
  }
  @media (max-width: ${WIDTH.tabletMin}) {
    margin-top: -124px;
  }
`;

export const SelectDropdownMultiSelect = styled.div<{
  open: boolean;
  anchor?: 'bottom' | 'top';
}>`
  width: 100%;
  background: ${(props) => '#2C2C2C'};
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.shadows.s2};
  position: absolute;
  top: calc(100% + 8px);
  max-height: 0;
  transition: max-height 0.15s ease-in-out;
  overflow: hidden;
  z-index: 100;
  &::-webkit-scrollbar {
    width: 8px;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-track {
    background: ${(props) =>
      props.theme.mode === 'dark' ? '#1d1d1d' : '#1d1d1d'};
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #6a6a6a;
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb:hover {
    border-radius: 4px;
  }
  ${(props) =>
    props.open && 'max-height: 450px;transition: max-height 0.25s ease-in-out;'}
`;

export const SelectOptionsAffiliates = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  cursor: pointer;
  color: ${(props) => props.theme.palette.text.t3};
  &:hover {
    background: ${(props) => '#353535'};
  }
`;

export const ImageContainerFirstSection = styled.div`
  width: 528px;
  height: 378px;
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 328px;
    height: 178px;
  }
`;

export const SellOnDrifflePhoneWeb = styled.img`
  position: absolute;
  bottom: 60px;
  right: 60px;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: none;
  }
`;

export const SellOnDrifflePhoneMobile = styled.img`
  display: none;
  @media (max-width: ${WIDTH.tabletMax}) {
    display: flex;
    margin: -180px 0 -400px;
  }
`;

export const Section2Wrapper = styled.div`
  width: 100%;
  height: 650px;
  padding: 56px 0;
  background: #171717;
  @media (max-width: ${WIDTH.tabletMax}) {
    height: fit-content;
  }
`;

export const Section2Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 48px;
  max-width: 1232px;
  margin: auto;
  align-items: center;
  justify-content: center;
  @media (max-width: ${WIDTH.tabletMax}) {
    gap: 32px;
    max-width: 100%;
    align-items: center;
    justify-content: center;
  }
`;

export const Section2ContentTitle = styled.div`
  font: 2.25rem/2.5rem Onest-Bold;
  color: ${COLORS.white};
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0 16px;
    font: 1.5rem /2rem Onest-Bold;
  }
`;

export const Section2CardContainer = styled.div`
  display: grid;
  gap: 16px;
  grid-template-columns: auto auto auto;
  justify-content: center;
  @media (max-width: ${WIDTH.laptopMid}) {
    grid-template-columns: auto auto;
    padding: 0 16px;
  }
  @media (max-width: ${WIDTH.mobileMax}) {
    grid-template-columns: auto;
    padding: 0 16px;
  }
`;

export const Section2Card = styled.div`
  border-radius: 16px;
  width: 380px;
  border: 1px solid #353535;
  padding: 16px;
  height: 142px;
  display: flex;
`;
export const Section2CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 16px;
  width: 380px;
  border: 1px solid #353535;
  padding: 24px;
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 100%;
  }
`;

export const Section2CardTop = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;
export const Section2CardBottom = styled.div`
  display: flex;
  padding-left: 48px;
  align-items: center;
`;

export const Section2CardTitle = styled.p`
  font-size: 18px;
  font-family: Onest-Medium;
  line-height: 24.3px;
  color: ${COLORS.white};
`;
export const Section2CardDescription = styled.p`
  color: #808080;
  font-size: 16px;
  line-height: 21.6px;
`;

export const Section3Wrapper = styled.div`
  width: 100%;
  height: 100%;
  padding: 56px 0;
  background: ${COLORS.black};
  @media (max-width: ${WIDTH.tabletMax}) {
    height: fit-content;
  }
`;

export const Section3Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1232px;
  margin: auto;
  align-items: center;
  @media (max-width: ${WIDTH.tabletMax}) {
    max-width: 100%;
    gap: 24px;
    align-items: center;
    justify-content: center;
  }
`;

export const LoginRegisterSectionContainer: any = styled.div`
  min-width: 552px;
  justify-content: center;
  padding: 40px 56px;
  background: ${'#171717'};
  border-radius: 12px;
  @media (max-width: ${WIDTH.tabletMax}) {
    min-width: 100%;
  }
`;

export const LoginRegisterSectionContainerHeading = styled.div`
  font-size: 18px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Onest-Medium;
  line-height: 24.3px;
  color: ${COLORS.white};
  margin-bottom: 24px;
`;

export const ThankyouContainer = styled.div`
  border-radius: 12px;
  max-width: 552px;
  gap:12px,
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 40px 56px;
  justify-content: center;
  border: 1px solid ${COLORS.blackLight1};
  background: ${'#171717'};
  @media (max-width: ${WIDTH.tabletMax}) {
    min-width: 100%;
    padding: 36px 10px;
  }
`;

export const ThankyouContainerHeading = styled.div`
  font-size: 18px;
  width: 100%;
  display: flex;
  margin-top: 12px;
  margin-bottom: 8px;
  justify-content: center;
  font-family: Onest-Medium;
  line-height: 24.3px;
  color: ${COLORS.white};
`;
export const ThankyouContainerSubHeading = styled.div`
  font-size: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: Onest-Medium;
  line-height: 24.3px;
  color: #808080;
`;

export const Section3ContentTitle = styled.div`
  font: 2.25rem/2.5rem Onest-Bold;
  color: ${COLORS.white};
  @media (max-width: ${WIDTH.tabletMax}) {
    padding: 0 16px;
    font: 1.5rem /2rem Onest-Bold;
  }
`;

export const Section3CardContainer = styled.div`
  display: grid;
  gap: 24px;
  margin-top: 40px;
  margin-bottom: 48px;
  padding: 0 16px;
  grid-template-columns: auto auto auto;
  justify-content: center;
  @media (max-width: ${WIDTH.tabletMax}) {
    grid-template-columns: auto;
    margin: 0;
  }
`;

export const Section3Card = styled.div`
  border-radius: 16px;
  border: 1px solid #353535;
  width: 300px;

  min-height: 292px;
  padding: 50px 34px 34px 34px;
  text-align: center;
  background-color: #212121;
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  @media (max-width: ${WIDTH.tabletMax}) {
    gap: 16px;
  }
`;

export const Section3IconBox = styled.div`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: #484848;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${COLORS.white};
  font: 56px/75px Onest-Bold;
  @media (max-width: ${WIDTH.tabletMax}) {
    width: 70px;
    height: 70px;
    font: 24px/62px Onest-Bold;
  }
`;
export const Section2IconBox = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #484848;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Section3CardTitle = styled.p`
  color: ${COLORS.white};
  font-size: 24px;
  line-height: 32.4px;
  font-family: Onest-SemiBold;
  @media (max-width: ${WIDTH.tabletMax}) {
    font-size: 16px;
    line-height: 22.4px;
  }
`;

export const Section3CardDescription = styled.p`
  color: #808080;
  font-size: 14px;
  line-height: 18.9px;
  @media (max-width: ${WIDTH.tabletMax}) {
    margin-top: -8px;
  }
`;

interface ContactUsBtnProps {
  width?: string;
  height?: string;
}

export const ContactUsBtn = styled.button<ContactUsBtnProps>`
  border-radius: 8px;
  width: ${(props) => props.width || '134px'};
  height: ${(props) => props.height || '48px'};
  border: 1px solid #353535;
  font-size: 1rem;
  &:active {
    transform: scale(0.95);
  }
  &:hover {
    background: ${COLORS.blackLight2};
  }
  line-height: 1rem;
  background-color: #171717;
  font-family: Onest-Bold;
  transition: 0.2s all ease-in-out;
  color: ${COLORS.white};
  cursor: pointer;
`;

export const SellerSideImage = styled.img`
  object-fit: contain;
  @media (max-width: ${WIDTH.laptopMax}) {
    width: 550px;
  }
`;

export const Section3CardMobile = styled.div`
  display: flex;
  gap: 16px;
  width: 500px;
  height: fit-content;
  border-radius: 16px;
  border: 1px solid #353535;
  padding: 16px;
  background-color: #212121;
  @media (max-width: ${WIDTH.mobileMax}) {
    width: 100%;
    align-items: center;
    padding: 12px;
  }
`;

export const Section3CardMobileContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 14px;
`;

export const Section3CardIconMobile = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #484848;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChannelLink = styled.div`
  padding: 24px;
  margin-top: 24px;
  background-color: ${'#1D1D1D'};
  border-radius: 12px;
  position: relative;
`;
