import { useTheme } from 'styled-components';
import { COLORS } from '../../constants/colors';
import { MaskIcon } from '../Icons/Icons';
import Input from '../InputComponents/Input';
import { ChannelLink, DAFBodySubHeading } from './BecomeAffiliateStyles';
import { useLocale } from '../../contexts/LocalizationContext';

const LinkDetails: React.FC<{
  index: number;
  items: {
    link: {
      name: string;
      value: string;
      error: string;
    };
    linkTimePeriod: {
      name: string;
      value: string;
      error: string;
    };
    communityCount: {
      name: string;
      value: string;
      error: string;
    };
  };
  handleInputChanges: any;
  handleRemoveLinkDetails: any;
  displayCross: boolean;
}> = ({
  index,
  items,
  handleInputChanges,
  handleRemoveLinkDetails,
  displayCross,
}) => {
  const theme = useTheme();
  const { messages } = useLocale();
  const {
    how_long_have_you_been_msg,
    how_big_is_your_community_msg,
    example_months_or_msg,
    add_a_link_msg,
    add_count_msg
  } = messages || {};

  return (
    <ChannelLink>
      <div style={{ position: 'absolute', right: 16 }}>
        {displayCross && (
          <MaskIcon
            onClick={() => handleRemoveLinkDetails(index)}
            url="/icons/cross.svg"
            height="12px"
            width="12px"
            style={{ marginBottom: '4px', cursor: 'pointer' }}
            selected
            color={theme.palette.text.t2}
          />
        )}
      </div>
      <DAFBodySubHeading style={{ marginTop: '-2px' }}>
        Link to the channel/website
      </DAFBodySubHeading>
      <Input
        placeholder={add_a_link_msg}
        style={{
          background: '#2C2C2C',
          color: COLORS.white,
          marginTop: '12px',
        }}
        name={'link'}
        onChange={(e) => handleInputChanges(e, index)}
        value={items['link']?.value || ''}
        error={items['link']?.error || ''}
      />
      <DAFBodySubHeading>{how_long_have_you_been_msg}</DAFBodySubHeading>
      <Input
        placeholder={example_months_or_msg}
        style={{
          background: '#2C2C2C',
          color: COLORS.white,
          marginTop: '12px',
        }}
        name={'linkTimePeriod'}
        onChange={(e) => handleInputChanges(e, index)}
        value={items['linkTimePeriod']?.value || ''}
        error={items['linkTimePeriod']?.error || ''}
      />
      <DAFBodySubHeading>{how_big_is_your_community_msg}</DAFBodySubHeading>
      <Input
        placeholder={add_count_msg}
        style={{
          background: '#2C2C2C',
          color: COLORS.white,
          marginTop: '12px',
        }}
        name={'communityCount'}
        onChange={(e) => handleInputChanges(e, index)}
        value={items['communityCount']?.value || ''}
        error={items['communityCount']?.error || ''}
      />
    </ChannelLink>
  );
};

export default LinkDetails;
