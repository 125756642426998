import { useRouter } from 'next/router';
import { useLocale } from '../../contexts/LocalizationContext';
import { useAppDispatch } from '../../redux/hooks';
import {
  openLoginModalRedux,
  openRegisterModalRedux,
} from '../../redux/modalSlice';
import { saveEventV3 } from '../../utils/eventTracking';
import {
  LoggedOutButtonsContainer,
  LoginBtnWrapper,
} from '../Header/HeaderStyle';
import Button from '../NewButton/Button';
import {
  LoginRegisterSectionContainer,
  LoginRegisterSectionContainerHeading,
} from './BecomeAffiliateStyles';

const LoginRegisterSection = () => {
  const router = useRouter();
  const dispatch = useAppDispatch();

  const { messages } = useLocale();
  const { login_msg, signup_msg } = messages || {};

  return (
    <LoginRegisterSectionContainer>
      <LoginRegisterSectionContainerHeading>
        {'Login/Register to access the form'}
      </LoginRegisterSectionContainerHeading>
      <LoggedOutButtonsContainer>
        <LoginBtnWrapper>
          <Button
            buttonTheme="grey"
            height="48px"
            width="100%"
            onClick={() => {
              saveEventV3({
                category: 'header',
                action: 'click',
                label: 'login',
                properties: '',
                value: [],
                from: router,
              });
              dispatch(openLoginModalRedux());
            }}
            ariaLabel="login"
          >
            {login_msg}
          </Button>
        </LoginBtnWrapper>
        <Button
          buttonTheme="primary"
          onClick={() => {
            saveEventV3({
              category: 'header',
              action: 'click',
              label: 'signup',
              properties: '',
              value: [],
              from: router,
            });
            dispatch(openRegisterModalRedux());
          }}
          height="48px"
          width="100%"
          ariaLabel="sign up"
        >
          {signup_msg}
        </Button>
      </LoggedOutButtonsContainer>
    </LoginRegisterSectionContainer>
  );
};

export default LoginRegisterSection;
