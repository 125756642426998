import { Drawer } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { MaskIcon } from '../Icons/Icons';
import {
  DrawerContainer,
  DrawerTitleContainer,
  Input,
  OptionsContainer,
  SelectOptions,
} from './InputComponentsStyles';

interface SelectDrawerProps {
  options: { value: string; name: string; disabled?: boolean; icon?: any }[];
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  title: string;
  placeholder?: string;
  handleOptionClick: (
    option: { name: string; value: string },
    i: number
  ) => void;
  hideInputInDrawer?: boolean;
  containerStyle?: any;
  dropDownStyle?: any;
  dropDownOptionStyle?: any;
  dropDownOptionStyleDisabled?: any;
  dropDownOptionStyleActive?: any;
  valueStyle?: any;
  showDivider?: boolean;
  value?: { value: string; name: string; disabled?: boolean; icon?: any };
}

const SelectDrawer: React.FC<SelectDrawerProps> = ({
  options,
  open,
  setOpen,
  title,
  value,
  handleOptionClick,
  placeholder,
  hideInputInDrawer,
  containerStyle,
  dropDownStyle,
  dropDownOptionStyle,
  dropDownOptionStyleDisabled,
  dropDownOptionStyleActive,
  valueStyle,
  showDivider,
}) => {
  const theme = useTheme();
  const [shownOptions, setShownOptions] = useState<
    { value: string; name: string; disabled?: boolean; icon?: any }[]
  >([]);
  const [inputValue, setInputValue] = useState<string>('');
  useEffect(() => {
    if (open && options) {
      setShownOptions(options);
    }
  }, [open, options]);
  useEffect(() => {
    if (options) {
      setShownOptions(
        options.filter((cur) =>
          cur.name.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }
  }, [options, inputValue]);
  return (
    <Drawer
      anchor="bottom"
      onClose={() => setOpen(false)}
      open={open}
      style={{ zIndex: 1001 }}
      sx={{
        '& .MuiDrawer-paper': {
          boxSizing: 'border-box',
          maxHeight: '100%',
          minHeight: 'auto',
          // maxHeight: '75vh',
        },
      }}
      PaperProps={{
        square: false,
        style: {
          borderRadius: '8px 8px 0 0',
          background: theme.palette.background.bg4,
        },
      }}
    >
      <DrawerContainer>
        <DrawerTitleContainer>
          <p>{title}</p>
          <MaskIcon
            url="/icons/close-24.svg"
            margin="0"
            selected
            color={theme.palette.text.t1}
            width="24px"
            height="24px"
          />
        </DrawerTitleContainer>
        {!hideInputInDrawer && (
          <Input
            // {...props}
            style={{ marginTop: '8px' }}
            autoFocus
            placeholder={placeholder}
            onChange={(e: any) => {
              const { value } = e.target;
              setInputValue(value);
            }}
            // value={inputValue}
          />
        )}
        <OptionsContainer>
          {shownOptions.map((cur, i) => {
            return (
              <div key={i}>
                <SelectOptions
                  onClick={() => handleOptionClick(cur, i)}
                  style={
                    cur.disabled
                      ? dropDownOptionStyleDisabled
                      : cur.value === value?.value
                      ? dropDownOptionStyleActive
                      : dropDownOptionStyle ?? {}
                  }
                >
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <div style={cur.disabled ? { filter: 'grayscale(1)' } : {}}>
                      {cur && cur.icon && cur.icon}
                    </div>
                    {cur.name}
                  </span>
                  <MaskIcon
                    url="/icons/tick.svg"
                    width="24px"
                    height="24px"
                    margin="0"
                    selected
                    color={theme.palette.text.primary}
                    style={{
                      display: cur.value === value?.value ? 'block' : 'none',
                    }}
                  />
                </SelectOptions>
                {showDivider && i !== options.length - 1 && (
                  <div
                    style={{
                      height: '1px',
                      width: '100%',
                      background: theme.palette.divider.d1,
                      margin: '2px 0',
                    }}
                  />
                )}
              </div>
            );
          })}
        </OptionsContainer>
      </DrawerContainer>
    </Drawer>
  );
};

export default SelectDrawer;
