import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useLocale } from '../../contexts/LocalizationContext';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import Button from '../NewButton/Button';
import {
  ImageContainerFirstSection,
  Section1Container,
  Section1Content,
  Section1Wrapper,
} from './BecomeAffiliateStyles';

export const FirstSectionPage = (props: any) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const router = useRouter();
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  const [redirectIntent, setRedirectIntent] = useState<boolean>(false);

  const { messages } = useLocale();
  const {
    become_an_affiliate_msg,
    start_earning_with_driffle_msg,
    turn_your_influence_into_an_msg,
  } = messages || {};

  useEffect(() => {
    if (isLoggedIn && redirectIntent) {
      router.push('/sell-on-driffle/verification');
    }
  }, [redirectIntent, isLoggedIn]);

  return (
    <>
      <Section1Wrapper style={{ position: 'relative' }}>
        <Section1Container>
          <Section1Content>
            <h6>
              {become_an_affiliate_msg}
              <br></br>
              <span>{start_earning_with_driffle_msg}</span>
            </h6>
            <div
              style={{
                color: theme.palette.text.t3,
              }}
            >
              {turn_your_influence_into_an_msg}
            </div>
            <div style={{ display: 'flex', gap: '16px', whiteSpace: 'nowrap' }}>
              <Button
                onClick={props.scrollToSection}
                buttonTheme="primary"
                height="48px"
              >
                {become_an_affiliate_msg}
              </Button>
            </div>
          </Section1Content>
          <ImageContainerFirstSection>
            <Image
              style={{ maxWidth: '528px', maxHeight: '378px' }}
              src={'/icons/aff.webp'}
              width="528px"
              height="378px"
              objectFit="contain"
            />
          </ImageContainerFirstSection>
          {/* <SellerSideImage
            src={'/icons/sell-on-driffle/Sell-on-driffle.webp'}
            height="337px"
            width="678px"
          /> */}
        </Section1Container>
        {/* <Section1Container2
          style={{
            position: 'absolute',
            bottom: '5%',
            left: '50%',
            right: '50%',
            transform: 'translate(-50%,-50%)',
          }}
        >
          <MaskIcon
            url="/icons/arrow_back_ios.svg"
            width="24px"
            height="24px"
            selected
            color={theme.palette.text.t2}
            margin="0"
          />
        </Section1Container2> */}
      </Section1Wrapper>
    </>
  );
};
