import Image from 'next/image';
import { useLocale } from '../../contexts/LocalizationContext';
import Button from '../NewButton/Button';
import {
  Section2CardBottom,
  Section2CardContainer,
  Section2CardContent,
  Section2CardDescription,
  Section2CardTitle,
  Section2CardTop,
  Section2Container,
  Section2ContentTitle,
  Section2IconBox,
  Section2Wrapper,
} from '../SellOnDrifflePage/SellOnDriffleStyles';

export const SecondSectionPage = (props: any) => {
  const { messages } = useLocale();
  const {
    why_become_a_driffle_affiliate_msg,
    become_an_affiliate_msg,
    your_choice_matters_msg,
    with_a_roster_of_over_msg,
    unlimited_earnings_msg,
    there_are_no_caps_on_msg,
    active_affiliate_support_msg,
    you_will_be_assisted_by_msg,
    cookie_validity_for_days_msg,
    month_long_cookie_period_thus_any_msg,
    accurate_tracking_and_reporting_msg,
    optimize_your_sales_with_efficient_msg,
    product_feeds_available_in_xml_msg,
    get_access_to_xml_product_msg,
  } = messages || {};

  const WhyDriffleAff = [
    {
      icon: '/icons/affiliates/icons8-correct.gif',
      title: your_choice_matters_msg,
      description: with_a_roster_of_over_msg,
    },
    {
      icon: '/icons/affiliates/icons8-money.gif',
      title: unlimited_earnings_msg,
      description: there_are_no_caps_on_msg,
    },
    {
      icon: '/icons/affiliates/icons8-talk.gif',
      title: active_affiliate_support_msg,
      description: you_will_be_assisted_by_msg,
    },
    {
      icon: '/icons/affiliates/icons8-cookie.gif',
      title: cookie_validity_for_days_msg,
      description: month_long_cookie_period_thus_any_msg,
    },
    {
      icon: '/icons/affiliates/icons8-graph.gif',
      title: accurate_tracking_and_reporting_msg,
      description: optimize_your_sales_with_efficient_msg,
    },
    {
      icon: '/icons/affiliates/icons8-book.gif',
      title: product_feeds_available_in_xml_msg,
      description: get_access_to_xml_product_msg,
    },
  ];
  return (
    <Section2Wrapper>
      <Section2Container>
        <Section2ContentTitle style={{ marginBottom: '24px' }}>
          {why_become_a_driffle_affiliate_msg}
        </Section2ContentTitle>

        <Section2CardContainer>
          {WhyDriffleAff.map((data: any, index: number) => {
            return (
              //   <Section2Card>
              //     <Section2IconBox>
              //       {/* <BackgroundIcon
              //         url={data.icon}
              //         // margin="0 6px 0 0"
              //         height="24px"
              //         width="24px"
              //         selected
              //       /> */}
              //     </Section2IconBox>
              //     <Section2CardContent>
              //       <Section2CardTitle>{data.title}</Section2CardTitle>
              //       <Section2CardDescription>
              //         {data.description}
              //       </Section2CardDescription>
              //     </Section2CardContent>
              //   </Section2Card>
              <Section2CardContent key={index}>
                <Section2CardTop>
                  <Section2IconBox>
                    <Image
                      src={data.icon}
                      width="32px"
                      height="32px"
                      alt="fetching gif"
                    />
                  </Section2IconBox>
                  <Section2CardTitle>{data.title}</Section2CardTitle>
                </Section2CardTop>
                <Section2CardBottom>
                  <Section2CardDescription>
                    {data.description}
                  </Section2CardDescription>
                </Section2CardBottom>
              </Section2CardContent>
            );
          })}
        </Section2CardContainer>
        <div style={{ display: 'flex', gap: '16px', whiteSpace: 'nowrap' }}>
          <Button
            onClick={props.scrollToSection}
            buttonTheme="primary"
            height="48px"
          >
            {become_an_affiliate_msg}
          </Button>
        </div>
      </Section2Container>
      {/* <Section1Container2
        style={{
          position: 'absolute',
          bottom: '5%',
          left: '50%',
          right: '50%',
          transform: 'translate(-50%,-50%)',
        }}
      >
        <MaskIcon
          url="/icons/arrow_back_ios.svg"
          width="24px"
          height="24px"
          selected
          color={theme.palette.text.t2}
          margin="0"
        />
      </Section1Container2> */}
    </Section2Wrapper>
  );
};
