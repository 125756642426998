import Image from 'next/image';
import { useTheme } from 'styled-components';
import {
  ThankyouContainer,
  ThankyouContainerHeading,
  ThankyouContainerSubHeading,
} from './BecomeAffiliateStyles';

const ThankyouSection = () => {
  const theme = useTheme();
  return (
    <ThankyouContainer>
      <Image
        src="/images/check_circle.png"
        width="96px"
        height="96px"
        alt="Thanks you affiliate"
      />
      <ThankyouContainerHeading>
        Thank you for submitting the form!
      </ThankyouContainerHeading>
      <ThankyouContainerSubHeading>
        Our team is currently reviewing your application, we’ll get back to you
        soon.
      </ThankyouContainerSubHeading>
    </ThankyouContainer>
  );
};

export default ThankyouSection;
