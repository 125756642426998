import { useCallback, useState } from 'react';
import { useTheme } from 'styled-components';
import { COLORS } from '../../constants/colors';
import { useLocale } from '../../contexts/LocalizationContext';
import { MaskIcon } from '../Icons/Icons';
import {
  AccordianDropDown,
  AccordianSummaryContainer,
  AccordianSummaryWrapper,
  NotificationItemTitle,
  NotificationItemsWrapper,
  TempAccordian,
} from '../SellerNotificationSettings/NotificationStyle';
import {
  Section2Container,
  Section2ContentTitle,
  Section2Wrapper,
} from './BecomeAffiliateStyles';
import FAQAccordian from './FAQAccordian';

// export const fAQuestions = [
//   'How much money will I get for bringing in a sale to Driffle?',
//   'When will I be able to withdraw my affiliate earnings?',
//   'Is there a minimum amount for a withdrawal?',
//   'What are the requirements to become an affiliate?',
//   `Is there a possibility of getting Driffle's product feed in an XML file?`,
// ];
// export const fAQsubQuestions = [
//   'The standard affiliate will get a 5% commission on the revenue share for each bought sale.All affiliates are eligible to increase this revenue share percentage by building a successful long-term partnership.',
//   'You can withdraw your affiliate earnings at the end of every month via bank transfer.',
//   'The minimum accumulated amount of affiliate earnings that you can withdraw is €50.00.',
//   'Bloggers, publishers, and content creators with a qualifying website, content channel, or mobile app are eligible to apply to the affiliate program. Affiliate type-detail requirements are included in the application form.',
//   'We provide XML product feeds once your affiliate account is approved for easy product integration and promotion in your preferred language and currency.',
// ];
const FAQSection = () => {
  const [accordianSelected, setAccordianSelected] = useState<string[]>([]);
  const handleChange = useCallback(
    (id: number) => {
      if (accordianSelected.includes(String(id))) {
        setAccordianSelected((prevState) =>
          prevState.filter((item) => item !== String(id))
        );
      } else {
        setAccordianSelected((prev) => [...prev, String(id)]);
      }
    },
    [accordianSelected]
  );
  const theme = useTheme();

  let { messages } = useLocale();
  const {
    how_much_money_will_i_msg,
    when_will_i_be_able_msg,
    is_there_a_minimum_amount_msg,
    what_are_the_requirements_to_msg,
    is_there_a_possibility_of_msg,
    faqs_msg
  } = messages || {};

  const fAQuestions = [
    how_much_money_will_i_msg,
    when_will_i_be_able_msg,
    is_there_a_minimum_amount_msg,
    what_are_the_requirements_to_msg,
    is_there_a_possibility_of_msg
  ];

  return (
    <>
      <Section2Wrapper style={{ height: '100%' }}>
        <Section2Container>
          <Section2ContentTitle style={{ marginBottom: '12px' }}>
            {faqs_msg}
          </Section2ContentTitle>
          {/* {isLoggedIn ? <ThankyouSection /> : <LoginRegisterSection />} */}
          <NotificationItemsWrapper
            style={{
              maxWidth: '762px',
              display: 'flex',
              flexDirection: 'column',
              marginTop: '-24px',
              marginBottom: '32px',
              gap: '16px',
              borderRadius: '0px',
              background: 'transparent',
            }}
          >
            {fAQuestions?.map((obj, index) => {
              return (
                <TempAccordian key={obj}>
                  {/* <Accordion
                    expanded={expanded === `panel` + Number(obj?.id)}
                    onChange={handleChange(`panel` + Number(obj?.id))}
                    >
                    <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  > */}
                  <AccordianSummaryWrapper
                    key={obj}
                    onClick={() => {
                      handleChange(index);
                    }}
                  >
                    <AccordianSummaryContainer>
                      <NotificationItemTitle style={{ color: COLORS.white }}>
                        {obj}
                      </NotificationItemTitle>
                    </AccordianSummaryContainer>
                    <AccordianDropDown
                      selected={accordianSelected.includes(String(index))}
                    >
                      <MaskIcon
                        id="visit-store-arrow-right"
                        url="/icons/notification-arrow.svg"
                        height="20px"
                        width="20px"
                        margin="0 0 0 -16px"
                        color={COLORS.white}
                        selected
                        // style={{ transform: 'rotate(180deg)' }}
                      />
                    </AccordianDropDown>
                  </AccordianSummaryWrapper>
                  <FAQAccordian obj={accordianSelected} index={index} />
                </TempAccordian>
              );
            })}
            <div
              style={{
                background: '#1D1D1D',
                borderRadius: '16px',
                padding: '24px',
              }}
            >
              <NotificationItemTitle style={{ color: COLORS.white }}>
                Have more questions? Reach out to us at{' '}
                <a href="mailto:affiliates@driffle.com">
                  affiliates@driffle.com
                </a>
              </NotificationItemTitle>
            </div>
          </NotificationItemsWrapper>
        </Section2Container>
      </Section2Wrapper>
    </>
  );
};

export default FAQSection;
