import styled from 'styled-components';

export const TickCheckboxInput = styled.input`
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
`;

export const CheckboxInputDiv = styled.div<{
  disabled?: boolean;
  selected?: boolean;
  changeCssBackground?: boolean;
}>`
  position: relative;
  width: 24px;
  height: 24px;
  background-image: ${(props) =>
    props.selected && 'url(/icons/check-white.svg)'};
  background-repeat: no-repeat;
  background-position: center;
  background-size: 15px;
  background-color: ${(props) =>
    props.selected
      ? props.theme.palette.primary.main
      : props.changeCssBackground
      ? '#2C2C2C'
      : props.theme.palette.background.bg2};
  border: 1px solid
    ${(props) =>
      props.selected
        ? props.theme.palette.primary.main
        : props.changeCssBackground
        ? undefined
        : props.theme.palette.border.b2};
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;
