import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { WIDTH } from '../../constants/screenResolution';
import { useLocale } from '../../contexts/LocalizationContext';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { useAppSelector } from '../../redux/hooks';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import {
  DAFContainer,
  DAFHeading,
  Section3Card,
  Section3CardContainer,
  Section3CardDescription,
  Section3CardMobile,
  Section3CardMobileContent,
  Section3CardTitle,
  Section3Container,
  Section3ContentTitle,
  Section3IconBox,
  Section3Wrapper,
} from './BecomeAffiliateStyles';
import DriffleAffiliateForm from './DriffleAffiliateForm';
import LoginRegisterSection from './LoginRegisterSection';

export const ThirdSectionPage = ({
  scrollToSection,
  specialSectionRef,
}: {
  scrollToSection: any;
  specialSectionRef: any;
}) => {
  const { messages } = useLocale();
  const {
    fill_out_the_form_msg,
    once_you_submit_the_form_msg,
    application_review_msg,
    our_affiliate_manager_will_contact_msg,
    start_your_journey_msg,
    get_access_to_your_affiliate_msg,
    how_to_start_msg,
    driffle_affiliate_form_msg,
    
  } = messages || {};

  const { width } = useWindowDimensions();
  const [redirectIntent, setRedirectIntent] = useState<boolean>(false);
  const router = useRouter();
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  const howItWorksList = [
    {
      icon: '1',
      title: fill_out_the_form_msg,
      description: once_you_submit_the_form_msg,
    },
    {
      icon: '2',
      title: application_review_msg,
      description: our_affiliate_manager_will_contact_msg,
    },
    {
      icon: '3',
      title: start_your_journey_msg,
      description: get_access_to_your_affiliate_msg,
    },
  ];
  useEffect(() => {
    if (isLoggedIn && redirectIntent) {
      router.push('/sell-on-driffle/verification');
    }
  }, [redirectIntent, isLoggedIn]);
  return (
    <Section3Wrapper>
      <Section3Container>
        <Section3ContentTitle style={{ marginBottom: '24px' }}>
          {how_to_start_msg}
        </Section3ContentTitle>
        <Section3CardContainer>
          {howItWorksList.map((data) => {
            return (
              <>
                {!!width &&
                width < getNumFromResolutionConstant(WIDTH.tabletMax) ? (
                  <Section3CardMobile>
                    <Section3IconBox>{data.icon}</Section3IconBox>
                    <Section3CardMobileContent>
                      <Section3CardTitle style={{ marginTop: '12px' }}>
                        {data.title}
                      </Section3CardTitle>
                      <Section3CardDescription>
                        {data.description}
                      </Section3CardDescription>
                    </Section3CardMobileContent>
                  </Section3CardMobile>
                ) : (
                  <Section3Card>
                    <Section3IconBox>{data.icon}</Section3IconBox>
                    <Section3CardTitle style={{ marginTop: '24px' }}>
                      {data.title}
                    </Section3CardTitle>
                    <Section3CardDescription>
                      {data.description}
                    </Section3CardDescription>
                  </Section3Card>
                )}
              </>
            );
          })}
        </Section3CardContainer>
        <DAFContainer>
          <DAFHeading ref={specialSectionRef}>
            {driffle_affiliate_form_msg}
          </DAFHeading>
          {isLoggedIn ? <DriffleAffiliateForm /> : <LoginRegisterSection />}
          {/* {isLoggedIn ? <ThankyouSection /> : <LoginRegisterSection />} */}
        </DAFContainer>
      </Section3Container>
    </Section3Wrapper>
  );
};
