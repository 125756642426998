import { Collapse } from '@mui/material';
import { useLocale } from '../../contexts/LocalizationContext';
import {
  NotificationCollapseContainer,
  NotificationItemTitle,
} from '../SellerNotificationSettings/NotificationStyle';

const FAQAccordian = ({ obj, index }: { obj: any; index: number }) => {
  let { messages } = useLocale();
  const {
    the_standard_affiliate_will_get_msg,
    you_can_withdraw_your_affiliate_msg,
    the_minimum_accumulated_amount_of_msg,
    bloggers_publishers_and_content_creators_msg,
    we_provide_xml_product_feeds_msg,
  } = messages || {};

  const fAQsubQuestions = [
    the_standard_affiliate_will_get_msg?.replace('[--PERCENT--]', '5% '),
    you_can_withdraw_your_affiliate_msg,
    the_minimum_accumulated_amount_of_msg?.replace('[--AMOUNT--]', '€50.00.'),
    bloggers_publishers_and_content_creators_msg,
    we_provide_xml_product_feeds_msg,
  ];
  return (
    <>
      <Collapse
        in={obj.includes(String(index))}
        easing={'ease-in-out'}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <div style={{ marginBottom: '24px' }}>
          <NotificationCollapseContainer
            key={index}
            // onClick={(e) => {
            //   e.preventDefault();
            // }}
          >
            <NotificationItemTitle
              style={{
                fontSize: '16px',
                fontFamily: 'Onest-Regular',
                marginLeft: '8px',
                display: 'flex',
                marginTop: '-8px',
                color: '#808080',
              }}
            >
              {fAQsubQuestions[index]}
            </NotificationItemTitle>
          </NotificationCollapseContainer>
        </div>
      </Collapse>
    </>
  );
};

export default FAQAccordian;
