import { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { COLORS } from '../../constants/colors';
import { MaskIcon } from '../Icons/Icons';
import { ButtonContentWrapper } from '../InputComponents/InputComponentsStyles';
import Button from '../NewButton/Button';
import TextFields from './TextFields';
import { useLocale } from '../../contexts/LocalizationContext';

const MultiTextFields = ({
  headingTitle,
  handleWebsitePromotion,
  placeholder,
  nameVal,
  checkEmpty,
}: any) => {
  const theme = useTheme();
  const [textFieldArray, setTextFieldArray] = useState<
    { name: string; value: string; error: string }[]
  >([{ name: nameVal, value: '', error: '' }]);

  const handleAddAnother = useCallback(() => {
    setTextFieldArray((prev) => [
      ...prev,
      { name: nameVal, value: '', error: '' },
    ]);
  }, []);

  const handleInputChanges = useCallback(
    (e, index) => {
      setTextFieldArray((prev) =>
        prev.map((item, index2) => {
          if (index === index2) {
            return {
              ...item,
              error: '',
              value: e.target.value,
            };
          } else {
            return { ...item };
          }
        })
      );
    },
    [textFieldArray]
  );

  const ErrorEmptyTextfield = useCallback(() => {
    let temp = textFieldArray?.map((item: any, index: any) => {
      if (item.value === '') {
        return {
          ...item,
          error: 'This field is required',
        };
      } else {
        return item;
      }
    });
    setTextFieldArray(temp);
  }, [textFieldArray]);

  const handleRemoveTextField = useCallback((index) => {
    setTextFieldArray((prev) => prev.filter((_, index2) => index2 !== index));
  }, []);

  useEffect(() => {
    handleWebsitePromotion(textFieldArray);
  }, [handleWebsitePromotion, textFieldArray]);

  const { messages } = useLocale();
  const {
    add_another_msg
  } = messages || {};

  // useEffect(() => {
  //   if (checkEmpty && nameVal !== 'websitePromotion') {
  //     ErrorEmptyTextfield();
  //   }
  // }, [checkEmpty]);

  return (
    <>
      {/* <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', right: 10 }}> */}
      {textFieldArray?.map((item, index) => {
        return (
          <>
            <TextFields
              index={index}
              item={item}
              placeholder={placeholder}
              name={nameVal}
              displayCross={textFieldArray.length > 1}
              headingTitle={headingTitle}
              handleInputChanges={handleInputChanges}
              handleRemoveTextField={handleRemoveTextField}
            />
          </>
        );
      })}
      <Button
        onClick={handleAddAnother}
        // buttonTheme="#2C2C2C"
        style={{ width: '100%', marginTop: '16px', background: '#2C2C2C' }}
      >
        <ButtonContentWrapper>
          <MaskIcon
            url="/icons/add-circle_ubo.svg"
            width="24px"
            height="24px"
            selected
            color={COLORS.white}
            margin="0px 2px 0 0"
          />
          {add_another_msg}
        </ButtonContentWrapper>
      </Button>
      {/* </div>
      </div> */}
    </>
  );
};

export default MultiTextFields;
