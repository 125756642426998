import React, { useCallback, useEffect, useState } from 'react';
import { COLORS } from '../../constants/colors';
import { useLocale } from '../../contexts/LocalizationContext';
import { MaskIcon } from '../Icons/Icons';
import { ButtonContentWrapper } from '../InputComponents/InputComponentsStyles';
import Button from '../NewButton/Button';
import SelectSub from './Selectsub';

interface CountryPropsI {
  handleAudienceLang: any;
  value?: { value: string; name: string };
  checkAudienceError: any;
}

const MultiSelectCountry: React.FC<CountryPropsI> = ({
  handleAudienceLang,
  checkAudienceError,
  // error: any,
}) => {
  const [selectedCountryList, setSelectedCountryList] = useState<
    { value: string; name: string }[]
  >([{ value: '', name: '' }]);
  const [errorLang, setErrorLang] = useState<boolean>(false);
  const [countAddAnother, setCountAddAnother] = useState<number[]>([0]);
  const handleAddAnother = useCallback(() => {
    setSelectedCountryList((prev) => [...prev, { value: '', name: '' }]);
    setCountAddAnother((prev) => [...prev, prev.length + 1]);
  }, []);
  const { messages } = useLocale();
  const { add_another_msg } = messages || {};

  const handleSelectedCountryList = useCallback(
    (val, index) => {
      setSelectedCountryList((prev) => {
        return prev.map((item, index2) => {
          if (index === index2) {
            return { ...val };
          } else {
            return { ...item };
          }
        });
      });
    },
    [setSelectedCountryList]
  );

  const handleRemoveLang = useCallback((index) => {
    setSelectedCountryList((prev) => {
      return prev.filter((_, index2) => {
        return index2 !== index;
      });
    });
    setCountAddAnother((prev) => {
      return prev.filter((_, index2) => {
        return index2 !== index;
      });
    });
  }, []);

  useEffect(() => {
    if (selectedCountryList && selectedCountryList.length > 0) {
      let tempArr = selectedCountryList?.map((cur: any) => {
        return cur?.name;
      });
      handleAudienceLang(tempArr);
    }
  }, [selectedCountryList]);

  useEffect(() => {
    if (checkAudienceError) {
      const emptyList = selectedCountryList?.some((cur) => {
        return cur.name === '';
      });
      setErrorLang(emptyList);
    }
  }, [checkAudienceError, selectedCountryList]);

  return (
    <>
      <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
        {countAddAnother?.map((cur, index) => {
          return (
            <SelectSub
              // error={error}
              index={index}
              removeCrossButton={countAddAnother.length < 2}
              handleRemoveLang={handleRemoveLang}
              handleSelectedCountryList={handleSelectedCountryList}
              key={cur}
            />
          );
        })}
      </div>

      {errorLang && (
        <h6
          style={{
            color: COLORS.red3,
            marginTop: '12px',
            font: '14px/16px Onest-Medium',
          }}
        >
          {'These fields are required'}
        </h6>
      )}
      <Button
        onClick={handleAddAnother}
        // buttonTheme="#2C2C2C"
        style={{ width: '100%', marginTop: '16px', background: '#2C2C2C' }}
      >
        <ButtonContentWrapper>
          <MaskIcon
            url="/icons/add-circle_ubo.svg"
            width="24px"
            height="24px"
            selected
            color={COLORS.white}
            margin="0px 2px 0 0"
          />
          {add_another_msg}
        </ButtonContentWrapper>
      </Button>
    </>
  );
};

export default MultiSelectCountry;
