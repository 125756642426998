import { CheckboxInputDiv, TickCheckboxInput } from './CheckboxInputStyle';

function CheckboxInput(props: {
  checked: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  hash?: string;
  changeCssBackground?: boolean;
}) {
  return (
    <label>
      <TickCheckboxInput
        type="checkbox"
        id={props.hash}
        disabled={props.disabled || false}
        onChange={props.onChange}
      />
      <div>
        <CheckboxInputDiv
          changeCssBackground={props.changeCssBackground}
          selected={props.checked}
          disabled={props.disabled}
        />
      </div>
    </label>
  );
}

export default CheckboxInput;
