import { useCallback, useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { COLORS } from '../../constants/colors';
import { MaskIcon } from '../Icons/Icons';
import { ButtonContentWrapper } from '../InputComponents/InputComponentsStyles';
import Button from '../NewButton/Button';
import LinkDetails from './LinkDetails';
import { useLocale } from '../../contexts/LocalizationContext';

const DAFSecondSection = ({
  handlePlatformInfo,
  checkEmpty,
  setCheckEmpty,
}: {
  handlePlatformInfo: any;
  checkEmpty: any;
  setCheckEmpty: any;
}) => {
  const theme = useTheme();
  const { messages } = useLocale();
  const {
    add_another_msg
  } = messages || {};
  const [linkDetails, setLinkDetails] = useState<
    {
      link: {
        name: string;
        value: string;
        error: string;
      };
      linkTimePeriod: {
        name: string;
        value: string;
        error: string;
      };
      communityCount: {
        name: string;
        value: string;
        error: string;
      };
    }[]
  >([
    {
      link: {
        name: 'link',
        value: '',
        error: '',
      },
      linkTimePeriod: {
        name: 'linkTimePeriod',
        value: '',
        error: '',
      },
      communityCount: {
        name: 'communityCount',
        value: '',
        error: '',
      },
    },
  ]);

  useEffect(() => {
    handlePlatformInfo(linkDetails);
  }, [linkDetails, handlePlatformInfo]);

  const handleInputChanges = useCallback(
    (e: any, indexTwo: number) => {
      let temp = linkDetails?.map((item: any, index: any) => {
        if (index === indexTwo) {
          return {
            ...item,
            [e.target.name]: {
              ...item[e.target.name],
              error: '',
              value: e.target.value,
            },
          };
        } else {
          return {
            ...item,
          };
        }
      });
      setLinkDetails(temp);
    },
    [linkDetails]
  );

  function isValidUrl(str: string) {
    try {
      new URL(str);
      return true;
    } catch (err) {
      return false;
    }
  }
  const handleErrorEmptyTextfield = useCallback(() => {
    let temp = linkDetails?.map((item: any, index: any) => {
      if (
        item.link.value === '' ||
        !isValidUrl(item.link.value) ||
        item.linkTimePeriod.value === '' ||
        item.communityCount.value === '' ||
        !!!item.communityCount.value
      ) {
        return {
          ...item,
          link: {
            ...item.link,
            error:
              item.link.value === ''
                ? 'This field is required'
                : !isValidUrl(item.link.value)
                ? 'Please enter a valid URl'
                : '',
          },
          linkTimePeriod: {
            ...item.linkTimePeriod,
            error:
              item.linkTimePeriod.value === '' ? 'This field is required' : '',
          },
          communityCount: {
            ...item.communityCount,
            error:
              item.communityCount.value === '' || !!!item.communityCount.value
                ? 'This field is required'
                : '',
          },
        };
      } else {
        return item;
      }
    });
    setCheckEmpty(false);
    setLinkDetails(temp);
  }, [linkDetails, setCheckEmpty]);

  const handleRemoveLinkDetails = useCallback(
    (indexTwo: number) => {
      let temp = linkDetails?.filter((item: any, index: any) => {
        if (index !== indexTwo) {
          return item;
        }
      });
      setLinkDetails(temp);
    },
    [linkDetails]
  );

  const handleAddAnother = useCallback(() => {
    setLinkDetails((prev: any) => [
      ...prev,
      {
        link: {
          name: 'link',
          value: '',
          error: '',
        },
        linkTimePeriod: {
          name: 'linkTimePeriod',
          value: '',
          error: '',
        },
        communityCount: {
          name: 'communityCount',
          value: '',
          error: '',
        },
      },
    ]);
  }, []);

  useEffect(() => {
    if (checkEmpty) {
      handleErrorEmptyTextfield();
    }
  }, [checkEmpty]);

  return (
    <>
      {linkDetails?.map((items, index) => {
        return (
          <LinkDetails
            key={index}
            index={index}
            items={items}
            handleInputChanges={handleInputChanges}
            displayCross={linkDetails.length > 1}
            handleRemoveLinkDetails={handleRemoveLinkDetails}
          />
        );
      })}
      <Button
        onClick={handleAddAnother}
        style={{ width: '100%', marginTop: '16px', background: '#2C2C2C' }}
      >
        <ButtonContentWrapper>
          <MaskIcon
            url="/icons/add-circle_ubo.svg"
            width="24px"
            height="24px"
            selected
            color={COLORS.white}
            margin="0px 2px 0 0"
          />
          {add_another_msg}
        </ButtonContentWrapper>
      </Button>
    </>
  );
};

export default DAFSecondSection;
