import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import Footer from '../../components/Footer/Footer';
import Header from '../../components/Header/Header';

import Navbar from '../../components/Navbar/Navbar';

import { useAppSelector } from '../../redux/hooks';

import FAQSection from '../../components/BecomeAffiliatePage/FAQSection';
import { FirstSectionPage } from '../../components/BecomeAffiliatePage/FirstSectionPage';
import { SecondSectionPage } from '../../components/BecomeAffiliatePage/SecondSectionPage';
import { ThirdSectionPage } from '../../components/BecomeAffiliatePage/ThirdSectionPage';
import { BodyWrapper } from '../../styles/pageStyles/sellOnDriffleStyle';
import { getUserInfoSSR } from '../../utils/cf';

// interface Props {
//   meta: string;
// }

const BecomeAffiliateOnDriffle = () => {
  const router = useRouter();
  const { isLoggedIn, user } = useAppSelector((state) => state.user);
  const [redirectIntent, setRedirectIntent] = useState<boolean>(false);
  const specialSectionRef = useRef(null);
  const scrollToSection = () => {
    if (specialSectionRef.current) {
      // @ts-ignore
      specialSectionRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };
  useEffect(() => {
    if (isLoggedIn && redirectIntent) {
      router.push('/sell-on-driffle/verification');
    }
  }, [redirectIntent, isLoggedIn]);

  return (
    <>
      <Head>
        <title>{'Become an Affiliate and Earn | Driffle'}</title>
      </Head>
      <Header />
      <Navbar />
      <BodyWrapper>
        <FirstSectionPage scrollToSection={scrollToSection} />
        <SecondSectionPage scrollToSection={scrollToSection} />
        <ThirdSectionPage
          scrollToSection={scrollToSection}
          specialSectionRef={specialSectionRef}
        />
        <FAQSection />
      </BodyWrapper>
      <Footer />
    </>
  );
};

export default BecomeAffiliateOnDriffle;

export async function getServerSideProps(context: any) {
  try {
    const userInfo = await getUserInfoSSR(context.req, context.res);
    return {
      props: {
        reduxInitialState: userInfo,
      },
    };
  } catch (err) {
    return {
      notFound: true,
    };
  }
}
