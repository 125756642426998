import React from 'react';
import {
  ErrorMessage,
  InputContainer,
  Input as TextInput,
} from './InputComponentsStyles';

interface InputPropsI extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
}

const Input: React.FC<InputPropsI> = (props) => {
  return (
    <InputContainer>
      <TextInput {...props} />
      {props.error && <ErrorMessage>{props.error}</ErrorMessage>}
    </InputContainer>
  );
};

export default Input;
