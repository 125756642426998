import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useContext, useEffect, useState } from 'react';
import { COLORS } from '../../constants/colors';
import { useLocale } from '../../contexts/LocalizationContext';
import { SnackbarContext } from '../../contexts/SnackbarContext';
import { useAppSelector } from '../../redux/hooks';
import { ContentBodyText } from '../../user/pages/DeclaredStock/DeclaredStockStyle';
import { saveEventV3 } from '../../utils/eventTracking';
import CheckboxInput from '../CheckBoxInput/CheckboxInput';
import { Divider } from '../CouponsPage/CouponsPageStyles';
import Input from '../InputComponents/Input';
import LoaderCircle from '../LoaderDots/LoaderCircle';
import Button from '../NewButton/Button';
import {
  ButtonContentWrapperAffiliate,
  DAFBody,
  DAFBodyAffiliateTypes,
  DAFBodyHeading,
  DAFBodySubHeading,
  IAgreeToTnCTextAff,
  ListTypeCheckboxes,
  SubHeadingDesc,
} from './BecomeAffiliateStyles';
import DAFSecondSection from './DAFSecondSection';
import MultiSelectCountry from './MultiSelectCountry';
import MultiTextFields from './MultiTextFields';
import ThankyouSection from './ThankyouSection';

export interface FieldInterfaceString {
  name: string;
  value: string;
  error: string;
}
export interface FieldInterfaceNumber {
  name: string;
  value: number;
  error: string;
}
export interface DAFPlatformInfo {
  link: FieldInterfaceString;
  linkTimePeriod: FieldInterfaceString;
  communityCount: FieldInterfaceNumber;
}
interface DAFdetailsInterface {
  firstNameLastName: FieldInterfaceString;
  affiliateName: FieldInterfaceString;
  affiliateType: FieldInterfaceString;
  platformInfo: Array<DAFPlatformInfo>;
  promotionalChannels: FieldInterfaceString;
  targetAudienceLang: Array<{ value: string; name: string }>;
  affiliateNetwork: Array<FieldInterfaceString>;
  specificProduct: FieldInterfaceString;
}
const DriffleAffiliateForm = () => {
  const { messages } = useLocale();
  const router = useRouter();
  const {
    become_an_affiliate_msg,
    first_and_last_name_msg,
    affiliate_name_msg,
    your_affiliate_link_promo_codes_msg,
    something_that_your_community_recognize_msg,
    platform_information_msg,
    social_media_msg,
    deal_website_msg,
    gaming_website_blog_msg,
    streamer_video_creator_msg,
    price_comparison_website_msg,
    affiliate_network_msg,
    other_channels_for_promotion_websitechannel_msg,
    what_are_your_target_audiences_msg,
    are_you_currently_active_on_msg,
    affiliate_network_publisher_id_msg,
    are_you_looking_for_any_msg,
    product_name_product_type_etc_msg,
    types_of_affiliate_msg,
    add_channel_msg,
    additional_information_msg,
    submit_msg,
  } = messages || {};

  const [showThanksPopUp, setShowThanksPopUp] = useState<boolean>(false);
  const [checkEmpty, setCheckEmpty] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState<boolean>(false);
  const { dispatchSnackbar } = useContext(SnackbarContext);
  const [platformInfo, setPlatformInfo] = useState<any>();
  const [websitePromotion, setWebsitePromotion] = useState<any>();
  const [audienceLang, setAudienceLang] = useState<any>();
  const [checkAudienceError, setCheckAudienceError] = useState<boolean>();
  const [affiliateNetworkVal, setAffiliateNetwork] = useState<any>();
  const [dafDetails, setDafDetails] = useState<DAFdetailsInterface>({
    firstNameLastName: {
      name: 'firstNameLastName',
      value: '',
      error: '',
    },
    affiliateName: {
      name: 'affiliateName',
      value: '',
      error: '',
    },
    affiliateType: {
      name: 'affiliateType',
      value: '',
      error: '',
    },
    platformInfo: [
      {
        link: {
          name: 'link',
          value: '',
          error: '',
        },
        linkTimePeriod: {
          name: 'linkTimePeriod',
          value: '',
          error: '',
        },
        communityCount: {
          name: 'communityCount',
          value: 0,
          error: '',
        },
      },
    ],
    promotionalChannels: {
      name: 'promotionalChannels',
      value: '',
      error: '',
    },
    targetAudienceLang: [{ value: '', name: '' }],
    affiliateNetwork: [{ name: 'affiliateNetwork', value: '', error: '' }],
    specificProduct: { name: 'specificProduct', value: '', error: '' },
  });
  const { user } = useAppSelector((state) => state.user);
  const [typeError, setTypeError] = useState<boolean>(false);
  const [tncUnread, setTncUnread] = useState<boolean>(true);
  const [tncError, setTncError] = useState<boolean>(false);
  const [listOfAffiliates, setListOfAffiliates] = useState<
    { displayName: string; checked: boolean }[]
  >([
    {
      displayName: social_media_msg ?? '',
      checked: false,
    },
    {
      displayName: deal_website_msg ?? '',
      checked: false,
    },
    {
      displayName: gaming_website_blog_msg ?? '',
      checked: false,
    },
    {
      displayName: streamer_video_creator_msg ?? '',
      checked: false,
    },
    {
      displayName: price_comparison_website_msg ?? '',
      checked: false,
    },
    {
      displayName: affiliate_network_msg ?? '',
      checked: false,
    },
    {
      displayName: 'Other',
      checked: false,
    },
  ]);

  useEffect(() => {
    setListOfAffiliates([
      {
        displayName: social_media_msg ?? '',
        checked: false,
      },
      {
        displayName: deal_website_msg ?? '',
        checked: false,
      },
      {
        displayName: gaming_website_blog_msg ?? '',
        checked: false,
      },
      {
        displayName: streamer_video_creator_msg ?? '',
        checked: false,
      },
      {
        displayName: price_comparison_website_msg ?? '',
        checked: false,
      },
      {
        displayName: affiliate_network_msg ?? '',
        checked: false,
      },
      {
        displayName: 'Other',
        checked: false,
      },
    ]);
  }, [
    social_media_msg,
    deal_website_msg,
    gaming_website_blog_msg,
    streamer_video_creator_msg,
    price_comparison_website_msg,
    affiliate_network_msg,
  ]);

  const handleInputChanges = useCallback((e: any) => {
    setDafDetails((prev: any) => ({
      ...prev,
      [e.target.name]: {
        ...prev[e.target.name],
        value: e.target.value,
        error: '',
      },
    }));
  }, []);

  const handleCheckedInputs = useCallback(
    (name) => {
      const temp: any = listOfAffiliates?.map((items) => {
        if (items.displayName === name) {
          setTypeError(false);
          return {
            ...items,
            checked: !items.checked,
          };
        } else {
          return {
            ...items,
          };
        }
      });
      setListOfAffiliates(temp);
    },
    [listOfAffiliates]
  );

  const handlePlatformInfo = useCallback((temp: any) => {
    setPlatformInfo(temp);
  }, []);

  const handleWebsitePromotion = useCallback((websitePromotion: any) => {
    setWebsitePromotion(websitePromotion);
  }, []);

  const handleAffiliateNetwork = useCallback((affiliateNetwork: any) => {
    setAffiliateNetwork(affiliateNetwork);
  }, []);

  const handleAudienceLang = useCallback((audienceLang: any) => {
    setAudienceLang(audienceLang);
  }, []);

  function isValidUrl(str: string) {
    try {
      new URL(str);
      return true;
    } catch (err) {
      return false;
    }
  }
  const handleSubmit = useCallback(async () => {
    // try {

    //TODO:error checking and data retrieving kaa alg fun
    let link = platformInfo.map((item: any) => {
      return item.link.value;
    });
    let communityCount = platformInfo.map((item: any) => {
      if (item?.communityCount?.value) {
        return item?.communityCount?.value + '-';
      } else {
        return;
      }
    });
    let linkTimePeriod = platformInfo.map((item: any) => {
      return item.linkTimePeriod.value;
    });
    const promotionalWebsite = websitePromotion?.map((item: any) => {
      return item.value;
    });
    const affiliateNetworkPublisherID = affiliateNetworkVal?.map(
      (item: any) => {
        return item.value;
      }
    );
    const tempp: any = listOfAffiliates
      ?.filter((item) => item.checked)
      .map((item) => item.displayName);

    const c = link?.some((item: any) => {
      return !isValidUrl(item) || item === '';
    });

    let liTime = linkTimePeriod?.some((item: any) => {
      return item === '';
    });
    let li3 = communityCount?.some((item: any) => {
      return item === '' || !item;
    });

    let li4 = promotionalWebsite?.some((item: any) => {
      return item.value === '';
    });
    let li5 = affiliateNetworkPublisherID?.some((item: any) => {
      return item === '';
    });
    let li6 = tempp?.some((item: any) => {
      return item.value === '';
    });
    let li7 = audienceLang?.some((item: any) => {
      return item === '';
    });

    if (!dafDetails?.firstNameLastName?.value) {
      setDafDetails((prev: any) => ({
        ...prev,
        firstNameLastName: {
          ...prev['firstNameLastName'],
          error: 'Field is required',
        },
      }));
    }
    if (!dafDetails?.affiliateName?.value) {
      setDafDetails((prev: any) => ({
        ...prev,
        affiliateName: {
          ...prev['affiliateName'],
          error: 'Field is required',
        },
      }));
    }
    // if (!dafDetails?.specificProduct?.value) {
    //   setDafDetails((prev: any) => ({
    //     ...prev,
    //     specificProduct: {
    //       ...prev['specificProduct'],
    //       error: 'Field is required',
    //     },
    //   }));
    // }
    if (!!li6 || tempp?.length < 1) {
      setTypeError(true);
    } else {
      setTypeError(false);
    }
    if (!!li7) {
      setCheckAudienceError(true);
    } else {
      setCheckAudienceError(false);
    }
    if (
      c ||
      liTime ||
      li3 ||
      li4 ||
      !dafDetails.firstNameLastName.value ||
      !dafDetails.affiliateName.value ||
      // !dafDetails?.specificProduct.value ||
      li6 ||
      tempp?.length < 1 ||
      li7 ||
      tncUnread
    ) {
      setCheckEmpty(true);
      dispatchSnackbar({
        type: 'failure',
        payload: tncUnread
          ? 'Please read our affiliate terms and conditions page'
          : 'Few of above mentioned fields are not valid',
        // payload: false
        //   ? 'Please read our terms and conditions page'
        //   : 'Please enter valid data',
      });
      return;
    } else {
      let formData = new FormData();
      formData.append('email', user?.email ?? '');
      formData.append('firstNameLastName', dafDetails.firstNameLastName.value);
      formData.append('affiliateName', dafDetails.affiliateName.value);
      formData.append('specificProduct', dafDetails?.specificProduct.value);
      formData.append('affiliateType', tempp);
      formData.append('channel_Links', link);
      formData.append('channelTimePeriod', linkTimePeriod);
      formData.append('communityCount', communityCount);
      formData.append('promotionalChannels', promotionalWebsite);
      formData.append('audienceLang', audienceLang);
      // formData.append(
      //   'targetAudienceLang',
      //   new Blob([JSON.stringify(dafDetails.targetAudienceLang)], {
      //     type: 'application/json',
      //   })
      // );
      formData.append('affiliateNetwork', affiliateNetworkPublisherID);
      setLoadingButton(true);
      saveEventV3({
        action: 'click',
        category: 'affiliate',
        label: 'affiliate_form_sumbit',
        value: ['success'],
        jsonData: {
          formData,
        },
        properties: '',
        from: router,
      });
      const response = await fetch(
        'https://script.google.com/macros/s/AKfycbzxy9x58MgkZOwxIDDwL5vPzgBP7TOPGrJM80aTbvgqcsBC8EBBI58tpD9MVlFa_HZuSw/exec',
        {
          method: 'POST',
          body: formData,
          mode: 'no-cors',
        }
      )
        .then((response) => {
          console.log('response', response);
          if (response) {
            setShowThanksPopUp(true);
            dispatchSnackbar({
              type: 'success',
              payload:
                "Your response has been recorded! We'll contact you withing 1-3 business days.",
            });
          }
          setLoadingButton(false);
        })
        .catch((error) => {
          console.log(error);
          setLoadingButton(false);
          dispatchSnackbar({
            type: 'failure',
            payload: "Your response wasn't recorded. Try again.",
          });
        });
    }
  }, [
    platformInfo,
    websitePromotion,
    affiliateNetworkVal,
    dafDetails,
    tncUnread,
    audienceLang,
    listOfAffiliates,
    user,
  ]);

  // const CheckIfEmpty = useCallback(() => {
  //   let noError: boolean = true;
  //   if()
  //   return noError;
  // }, [platformInfo, websitePromotion, affiliateNetworkVal, dafDetails]);

  if (user) {
    return (
      <>
        {showThanksPopUp ? (
          <ThankyouSection />
        ) : (
          <DAFBody>
            <DAFBodyHeading style={{ marginTop: '-16px' }}>
              {become_an_affiliate_msg}
            </DAFBodyHeading>
            <DAFBodySubHeading>{first_and_last_name_msg}</DAFBodySubHeading>
            <Input
              placeholder={'e.g., John Doe'}
              style={{
                background: '#2C2C2C',
                color: COLORS.white,
                marginTop: '12px',
              }}
              name={'firstNameLastName'}
              onChange={handleInputChanges}
              value={dafDetails?.firstNameLastName.value || ''}
              error={dafDetails?.firstNameLastName.error}
            />
            <DAFBodySubHeading>{affiliate_name_msg}</DAFBodySubHeading>
            <Input
              placeholder={something_that_your_community_recognize_msg}
              style={{
                background: '#2C2C2C',
                color: COLORS.white,
                marginTop: '12px',
              }}
              name={'affiliateName'}
              onChange={handleInputChanges}
              value={dafDetails?.affiliateName.value || ''}
              error={dafDetails?.affiliateName.error}
            />
            <SubHeadingDesc>
              {your_affiliate_link_promo_codes_msg}
            </SubHeadingDesc>
            <DAFBodySubHeading>{types_of_affiliate_msg}</DAFBodySubHeading>
            <ListTypeCheckboxes>
              {listOfAffiliates?.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '12px',
                    }}
                  >
                    <CheckboxInput
                      checked={item.checked}
                      changeCssBackground={true}
                      onChange={() => handleCheckedInputs(item.displayName)}
                    />
                    <DAFBodyAffiliateTypes style={{ marginTop: '-4px' }}>
                      {item.displayName}
                    </DAFBodyAffiliateTypes>
                  </div>
                );
              })}
              {typeError && (
                <h6 style={{ color: COLORS.red3, marginTop: '-12px' }}>
                  {'Field is required'}
                </h6>
              )}
            </ListTypeCheckboxes>
            <Divider
              style={{
                width: '100%',
                marginTop: '24px',
                background: '#353535',
              }}
            />
            <DAFBodyHeading>{platform_information_msg}</DAFBodyHeading>
            <DAFSecondSection
              handlePlatformInfo={handlePlatformInfo}
              checkEmpty={checkEmpty}
              setCheckEmpty={setCheckEmpty}
            />
            <DAFBodySubHeading style={{ marginTop: '32px' }}>
              {other_channels_for_promotion_websitechannel_msg}
            </DAFBodySubHeading>
            <MultiTextFields
              nameVal={'websitePromotion'}
              placeholder={add_channel_msg}
              headingTitle={''}
              checkEmpty={checkEmpty}
              handleWebsitePromotion={handleWebsitePromotion}
            />
            <DAFBodySubHeading style={{ marginTop: '32px' }}>
              {what_are_your_target_audiences_msg}
            </DAFBodySubHeading>
            <MultiSelectCountry
              handleAudienceLang={handleAudienceLang}
              checkAudienceError={checkAudienceError}
            />
            {/* <DAFBodySubHeading style={{ marginTop: '24px' }}>
              {"What are your target audience's language(s)?"}
            </DAFBodySubHeading>
            <Country
              value={{ value: 'english', name: 'english' }}
              handleChange={handleCountryChange}
              placeholder={'Select Country'}
              wrapperStyle={{
                border: `2px solid ${theme.palette.divider.d1}`,
                borderRadius: '8px',
              }}
            /> */}
            <Divider
              style={{
                width: '100%',
                marginTop: '24px',
                background: '#353535',
              }}
            />
            <DAFBodyHeading>{additional_information_msg}</DAFBodyHeading>
            <DAFBodySubHeading style={{ marginTop: '32px' }}>
              {are_you_currently_active_on_msg}
            </DAFBodySubHeading>
            <MultiTextFields
              headingTitle={''}
              nameVal={'Affiliate Network'}
              placeholder={affiliate_network_publisher_id_msg}
              handleWebsitePromotion={handleAffiliateNetwork}
            />
            <DAFBodySubHeading>{are_you_looking_for_any_msg}</DAFBodySubHeading>
            <Input
              placeholder={product_name_product_type_etc_msg}
              style={{
                background: '#2C2C2C',
                color: COLORS.white,
                marginTop: '12px',
              }}
              name={'specificProduct'}
              onChange={handleInputChanges}
              value={dafDetails?.specificProduct.value || ''}
              error={dafDetails?.specificProduct.error || ''}
            />
            <ContentBodyText
              style={{
                display: 'flex',
                gap: '12px',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                marginTop: '16px',
              }}
            >
              <CheckboxInput
                checked={!tncUnread}
                changeCssBackground={true}
                onChange={() => setTncUnread(!tncUnread)}
              />
              <IAgreeToTnCTextAff>
                I have read and agree with{' '}
                <Link href={'/affiliate-terms-and-conditions'} passHref>
                  <a target="_blank"> Affiliate Terms and Conditions &nbsp;</a>
                </Link>
              </IAgreeToTnCTextAff>
            </ContentBodyText>
            <Divider
              style={{
                width: '100%',
                marginTop: '24px',
                background: '#353535',
              }}
            />
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'center',
              }}
            >
              <Button
                onClick={handleSubmit}
                buttonTheme="primary"
                style={{ marginTop: '20px' }}
              >
                <ButtonContentWrapperAffiliate>
                  {loadingButton ? <LoaderCircle /> : submit_msg}
                </ButtonContentWrapperAffiliate>
              </Button>
            </div>
          </DAFBody>
        )}
      </>
    );
  } else {
    return <></>;
  }
};
export default DriffleAffiliateForm;
