import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import { WIDTH } from '../../constants/screenResolution';
import useWindowDimensions from '../../hooks/useWindowDimensions';
import { getNumFromResolutionConstant } from '../../utils/getNumberFromResolutionConstant';
import { Error } from '../CartPromo/CartPromoModalStyle';
import { MaskIcon } from '../Icons/Icons';
import {
  Input,
  SelectInput,
  SelectInputContainer,
  SelectOptionsContainer,
} from '../InputComponents/InputComponentsStyles';
import SelectDrawer from '../InputComponents/SelectDrawer';
import {
  SelectDropdownMultiSelect,
  SelectOptionsAffiliates,
} from './BecomeAffiliateStyles';

interface AutocompletePropsI {
  options: { value: string; name: string }[] | undefined;
  placeholder?: string;
  handleChange: (option: { value: string; name: string }) => void;
  value?: { value: string; name: string };
  width?: string;
  height?: string;
  style?: any;
  disabled?: boolean;
  wrapperStyle?: any;
  text?: string;
  onClear?: any;
  error?: string;
  type?: string;
  index?: number;
  removeCrossButton?: string;
}

const AutoCompleteMultiSelect: React.FC<AutocompletePropsI> = ({
  options,
  placeholder,
  value,
  handleChange,
  disabled,
  error,
  removeCrossButton,
  index,
  ...props
}) => {
  const { width } = useWindowDimensions();
  const theme = useTheme();
  const [inputValue, setInputValue] = useState<string>(value ? value.name : '');
  const dropdownRef = useRef<null | HTMLDivElement>(null);
  const dropdownContainerRef = useRef<null | HTMLDivElement>(null);
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [anchor, setAnchor] = useState<'bottom' | 'top'>('bottom');
  const [shownOptions, setShownOptions] = useState<
    { value: string; name: string }[]
  >([]);

  useEffect(() => {
    if (options) {
      setShownOptions(
        options.filter((cur) =>
          cur.name.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    }
  }, [options, inputValue]);

  useEffect(() => {
    if (showDropdown && options) {
      setShownOptions(options);
    }
  }, [showDropdown, options]);

  const toggleDropdown = useCallback(() => {
    if (!disabled) {
      setShowDropdown((prev) => !prev);
    }
  }, [disabled]);

  const handleSelectOption = useCallback(
    (option: { value: string; name: string }) => {
      handleChange(option);
      setShowDropdown(false);
    },
    [handleChange]
  );

  const handleClickOutside = useCallback(
    (e: any) => {
      const { target } = e;
      if (dropdownRef.current) {
        if (!dropdownRef.current.contains(target)) {
          setShowDropdown(false);
        }
      }
    },
    [dropdownRef]
  );
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (value) {
      setInputValue(value.name);
    }
  }, [value, showDropdown]);

  useEffect(() => {
    if (dropdownContainerRef.current) {
      const nextEl = document.getElementById('__next');
      if (nextEl) {
        const diff =
          nextEl?.getBoundingClientRect().height -
          dropdownContainerRef.current.getBoundingClientRect().y;
        if (diff <= 400) {
          setAnchor('top');
        } else {
          setAnchor('bottom');
        }
      }
    }
  }, [dropdownContainerRef]);

  return (
    <>
      <SelectInputContainer ref={dropdownRef} style={props.wrapperStyle}>
        {!showDropdown ? (
          <SelectInput
            // tabIndex={0}
            // onFocus={() => setShowDropdown(true)}
            // onBlur={() => setShowDropdown(false)}
            style={
              props.style ? (!value ? { ...props.style } : props.style) : {}
            }
            onClick={toggleDropdown}
            width={props.width}
            disabled={disabled}
            height={props.height}
          >
            <p style={!value ? { color: theme.palette.text.t3 } : {}}>
              {value?.name || placeholder}
            </p>
            {value?.name &&
            props.onClear !== undefined &&
            !removeCrossButton ? (
              <MaskIcon
                url="/icons/close-24.svg"
                width="20px"
                height="20px"
                margin="0"
                selected
                color={theme.palette.text.t3}
                onClick={(e) => {
                  e.stopPropagation();
                  if (props.onClear !== undefined) {
                    props.onClear(index);
                  }
                }}
              />
            ) : (
              <MaskIcon
                url="/icons/arrow-down-chevron-24.svg"
                width="20px"
                height="20px"
                margin="0"
                selected
                color={theme.palette.text.t3}
              />
            )}
          </SelectInput>
        ) : (
          <Input
            {...props}
            autoFocus
            placeholder={placeholder}
            onChange={(e: any) => {
              const { value } = e.target;
              setInputValue(value);
            }}
            value={inputValue}
            // onFocus={() => setShowDropdown(true)}
            // onBlur={() => setShowDropdown(false)}
          />
        )}
        {options ? (
          width && width > getNumFromResolutionConstant(WIDTH.mobileMax) ? (
            <SelectDropdownMultiSelect
              anchor={anchor}
              ref={dropdownContainerRef}
              open={showDropdown}
            >
              <SelectOptionsContainer>
                {shownOptions.length === 0 ? (
                  <p style={{ color: theme.palette.text.t3, fontSize: '14px' }}>
                    No Results Found
                  </p>
                ) : (
                  shownOptions.map((cur, i) => (
                    <SelectOptionsAffiliates
                      onClick={() => handleSelectOption(cur)}
                      key={cur.value}
                    >
                      {cur.name}
                    </SelectOptionsAffiliates>
                  ))
                )}
              </SelectOptionsContainer>
            </SelectDropdownMultiSelect>
          ) : (
            <SelectDrawer
              options={options}
              open={showDropdown}
              setOpen={setShowDropdown}
              placeholder={placeholder}
              title={props.text || 'Select an option'}
              handleOptionClick={handleSelectOption}
            />
          )
        ) : null}
      </SelectInputContainer>
      {!!error && <Error>{error}</Error>}
    </>
  );
};

export default AutoCompleteMultiSelect;
