import { useTheme } from 'styled-components';
import { COLORS } from '../../constants/colors';
import { MaskIcon } from '../Icons/Icons';
import Input from '../InputComponents/Input';

const TextFields = ({
  headingTitle,
  item,
  displayCross,
  index,
  placeholder,
  name,
  handleRemoveTextField,
  handleInputChanges,
}: {
  headingTitle: string;
  item: any;
  index: any;
  placeholder: any;
  name: any;
  handleInputChanges: any;
  displayCross: any;
  handleRemoveTextField: any;
}) => {
  const theme = useTheme();
  return (
    <>
      <div style={{ position: 'relative' }}>
        <div style={{ position: 'absolute', right: 10, bottom: -50 }}>
          {displayCross && (
            <MaskIcon
              onClick={() => handleRemoveTextField(index)}
              url="/icons/cross.svg"
              height="12px"
              width="12px"
              style={{
                marginBottom: '4px',
                cursor: 'pointer',
              }}
              selected
              color={theme.palette.text.t2}
            />
          )}
        </div>
      </div>
      <Input
        placeholder={placeholder}
        style={{
          background: '#2C2C2C',
          color: COLORS.white,
          marginTop: '12px',
        }}
        name={name}
        onChange={(e) => handleInputChanges(e, index)}
        value={item?.value || ''}
        error={item?.error}
      />
    </>
  );
};

export default TextFields;
